.classroom-container{
    margin: 150px auto !important;
}
.lesson-actions-toggler{
    margin-bottom: 30px !important;
}
.video-users{
    height: 300px;
    overflow-y: auto;
}
.video-controls{
    cursor: pointer;
}