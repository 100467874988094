.app-main{
width: 95%;
padding: 50px 50px;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
margin: 20px auto;
gap: 20px;
}
@media screen and (max-width: 950px){
    .app-main{
    overflow-x: hidden;
    width: 100%;
    padding: 5px 5px;
    background: hsl(210, 25%,8%);
    align-items: flex-end;
}
}
.user-settings{
    border-radius: 20px;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.settings-title{
    text-align: center;
}
.setting-title{
    text-align: center;
    background-color: #000;
    border-radius: 5px;
    padding: 10px 0;
}
.create-setting{
    margin-left: 0;
}
    @media screen and (max-width: 700px){
        .create-setting{
        align-self: center;
        margin-left: auto;
    }
}
.update-btn{
    margin: 0;
    padding:5px 10px;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--style-blue-dark);
    color: var(--style-blue-cyan);
}
.update-input{
    width: 30%;
    height: 35px;
    padding: 10px;
    border-radius: 4px;
}
    @media screen and (max-width: 700px){
        .create-setting{
        width:100%;
    }
}
#currency-select{
width: 30%;
padding: 5px;
}
@media screen and (max-width:700px){
    #currency-select{
    width:100%
}
}
#currency-option{
width: 100%;
}
.shop-settings{
    border-radius: 20px;
    width: 100%;
    padding: 20px;
}
.bank-form{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    flex-wrap: wrap;
}
    @media screen and (max-width:950px) {
        .bank-form{
    flex-direction: column;
    padding-left: 0px;
    gap: 20px;
    }
}
.bank-input{
width: 250px;
height: 35px;
padding: 10px;
}
@media screen and (max-width:950px) {
    .bank-input{
    width: 100%;
}
}
.app-settings-form{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    margin-top: 20px;
}
    @media screen and (max-width:700px){
        .app-settings-form{
        flex-direction: column;
    }
}
.app-settings-form-togglers{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin-top: 20px;
}
    @media screen and (max-width:400px){
    .app-settings-form-togglers{
        flex-direction: column;
    }
}
.toggle-off,.setting-spinner{
    cursor: pointer;
    font-size: 24px;
}
.app-setting-spinner{
    background: var(--bg) !important;
    color:red;
}
.trash-can{
    cursor: pointer;
    font-size: 24px;
}