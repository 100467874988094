.auth-container{
    /* padding-top: 100px; */
    width: 100%;
}
.img-overlay{
    background-color: red;
    width: 171px;
    height: 180px;
    position: absolute;
    top: 100px;
    background: transparent;
    cursor: pointer;
}
.img-overlay::-webkit-file-upload-button {
    visibility: hidden;
  }
  
  .img-overlay::before {
    content: 'Change Image';
    color: white;
  }
.register-container{
    width: 60%;
    margin: 100px auto !important;
}
@media only screen and (max-width:800px){
    .register-container{
        width: 90%;
    }   
}
/* form{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
} */
 .list-group-item{
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
 }
 .code-block{
    box-shadow: 1px 1px 1px rgb(220,220,220);
    padding: 30px;
 }
 .add-code{
    margin: 20px !important;
    width: 50px !important;
    height: 50px !important;
    background-color: var(--primary-2) !important;
    color: var(--primary-1) !important;
    padding: 10px !important;
    cursor: pointer !important;
    text-align: center;
    border-radius: 50%;
    box-shadow: 1px 1px 1px rgb(220,220,220);
 }