.spaces-container{
    margin: 100px auto !important;
    background-color: var(--light-bg) !important;
    padding: 30px !important;
}
.space-title{
    text-align: center !important;
    font-weight: bold !important;
    padding-bottom: 30px !important;
}
.card-title{
    font-size: 18px;
    padding-bottom: 10px;
    font-family: var(--family);
    text-align: center;
}
.card-title-custom{
    font-size: 26px !important;
    padding-bottom: 10px;
    text-align: center;
    font-weight: bolder !important;
}
.card-text-spaces-custom{
    text-decoration-line: underline !important;
    text-decoration-color: gold !important;
    text-decoration-thickness: 5px;
}
.card-custom-display{
    min-height: 500px !important;
}
.card-text-title{
    color: var(--danger-1) !important;
    font-size: 25px !important;
    font-weight: 1000 !important;
    padding: 30px !important;
}
.btn{
    background-color: var(--primary-1) !important;
    color: var(--primary-2) !important;
    font-family: var(--family) !important;
}
.checkout-btn{
    border-radius: 25px !important;
    background-color: var(--danger-1) !important;
    border: 2px solid rgb(202, 22, 22) !important;
}
.contact-btn{
    border-radius: 5px !important;
    background-color: var(--danger-1) !important;
    border: none !important;
}
.course-container{
    margin: 100px auto !important;
    width:60%;
}
.category{
    font-weight: 500;
    font-size: 26px;
    color: var(--primary-1);
}
.btn-group{
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    flex-wrap: wrap !important;
}
.cost{
    font-size: 20px;
    color: var(--primary-1);
    text-align: right;
    font-weight: bolder;
}
@media only screen and (max-width:900px) {
    .btn-group{
        flex-direction: column-reverse !important;
    }
    .course-container{
        margin: 100px auto !important;
        width:50%;
        width: 100%;
    }
}
@media only screen and (max-width:400px) {
    .courses-card{
        /* width: 95vw !important; */
        margin:  auto !important;
    }
}
.card-text-courses{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: 100%;
}
.card-text{
    text-align: justify;
}
.card-img{
    /* height: 200px; */
}

.payment-succes{
    background-color: var(--primary-1);
    width: 60% !important;
    border-radius: 10px;
}
.payment-succes p{
    padding: 20px !important;
    color: var(--primary-2);
    text-align: center;
}
.anchor-btn{
    text-decoration: none;
    background-color: var(--primary-1);
    color: var(--primary-2);
    padding: 6px 20px;
    border-radius: 4px;
}
.card-classroom{
    box-shadow: none !important;
    border: none !important;
}
.col-user{
    float: right !important;
}
.col-tutor{
    float: left !important;
}
.chart-area{
    padding: 20px;
}
.text-user,.text-tutor{
    font-size: 14px;
    color: red;
}
.text-tutor{
    color: var(--primary-1);
}
.text-user{
    /* color: var(--primary-2); */
}
.note-container{
    display: flex;
    width: 100%;
    padding: 20px;
    align-items: flex-start;
    flex-direction: column;
}
.note-title{
    color: #333;
}
.note-text{
    color: gray;
    font-style: italic;
    font-size: 12px;
}