.courses-container{
    margin: 100px auto !important;
    background-color: var(--light-bg) !important;
    padding: 30px 50px !important;
}
.courses-title{
    text-align: center !important;
    font-weight: bold !important;
    padding-bottom: 30px !important;
}
.main-container{
    padding: 50px 30px !important;
    background-color: var(--style-bright);
}
.clear-log-btn{
    padding: 20px !important;
    border-radius: 4px;
    border: none;
    color: var(--danger-1);
}
.courses-cards{
    display: flex !important;
    gap: 10px !important;
}
.courses-card{
    transform: scale(1);
    cursor: pointer;
    /* width: 285px !important;
    height: 400px !important; */
}
.courses-card:hover{
    transform: scale(1.05);
    transition: ease-in-out 1s;
}
.courses-card-image{
    transform: scale(1);
}
.courses-card-image:hover{
    transform: scale(1.05);
    transition: ease-in-out 1s;
}
.title{
    font-size: 18px;
    padding: 30px;
    font-family: var(--family);
    text-align: center;
}
.btn{
    background-color: var(--primary-1) !important;
    color: var(--primary-2) !important;
    font-family: var(--family) !important;
}
.page404{
    background-image: url(../Pages/LoadingImg.jpg);
    background-color: var(--danger-1);
    background-size: cover;
    width:100% !important;
    height: 300px;
}
.title404{
    text-align: center;
    font-size: 36px;
    font-weight: bolder;
    color: var(--danger-1);
}
.content404{
    text-align: center;
    font-size: 16px;
    font-family: var(--family);
    color: var(--danger-3);
}
.course-container{
    margin: 100px auto !important;
    width:60%;
}
.category{
    font-weight: 500;
    font-size: 26px;
    color: var(--primary-1);
}
.btn-group{
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    flex-wrap: wrap !important;
}
.cost{
    font-size: 20px;
    color: var(--primary-1);
    text-align: right;
    font-weight: bolder;
}
.payment-gateway{
    width: 50% !important;
    /* height: 4000px !important; */
    margin: 150px auto !important;
}
.payment-card{
    width: 100% !important;
    margin: auto !important;
}
@media only screen and (max-width:900px) {
    .btn-group{
        flex-direction: column-reverse !important;
    }
    .course-container{
        margin: 100px auto !important;
        width:50%;
        width: 100%;
    }
    .payment-gateway{
        width: 100% !important;
    }
}
@media only screen and (max-width:400px) {
    .courses-card{
        /* width: 95vw !important; */
        margin:  auto !important;
    }
}
.card-text-courses{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: 100%;
}
.card-text{
    text-align: justify;
}
.card-img{
    /* height: 200px; */
}

.payment-succes{
    background-color: var(--primary-1);
    width: 60% !important;
    border-radius: 10px;
}
.payment-succes p{
    padding: 20px !important;
    color: var(--primary-2);
    text-align: center;
}
.anchor-btn{
    text-decoration: none;
    background-color: var(--primary-1);
    color: var(--primary-2);
    padding: 6px 20px;
    border-radius: 4px;
}
.card-classroom{
    box-shadow: none !important;
    border: none !important;
}
.col-user{
    float: right !important;
}
.col-tutor{
    float: left !important;
}
.chart-area{
    padding: 20px;
}
.text-user,.text-tutor{
    font-size: 14px;
    color: red;
}
.text-tutor{
    color: var(--primary-1);
}
.text-user{
    /* color: var(--primary-2); */
}
.note-container{
    display: flex;
    width: 100%;
    padding: 20px;
    align-items: flex-start;
    flex-direction: column;
}
.note-title{
    color: #333;
}
.note-text{
    color: gray;
    font-style: italic;
    font-size: 12px;
}
.payment-gateway-btn-proceed{
    background-color: var(--danger-1) !important;
}
.payment-gateway-btn-cancel{
    background-color: var(--light-bg) !important;
    color: gray !important;
}