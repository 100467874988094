/* Filename: styles.css */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300');

:root{
  --family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  --primary-1:rgb(17, 76, 164);
  --primary-2:#ffff;
  --danger-1:rgb(192, 58, 58);
  --danger-2:#ffff;
  --danger-3:rgb(209, 174, 174);
  --style-skye-blue:skyblue;
  /* --style-blue-dark: rgb(3, 25, 71);
  --style-blue-footer:hsl(210,25%,8%); */
  --gold:gold;
  --style-blue-dark: rgb(200, 34, 73);
  --style-blue-footer:hsl(341, 99%, 48%);
  --style-white: #fff;
  --style-bright: #f5f6fa;
  --light-bg:rgb(242, 234, 234);
  --style-dark: #1e2532;
  --style-black: #000;
}
body {
    /* background-color: rgb(219, 224, 227) !important; */
    font-family: 'Comfortaa', sans-serif !important;
    margin: 0;
}

.top-pane {
  background-color: hsl(225, 6%, 25%);
}

.pane {
  height: 50vh;
  display: flex;
}

.editor-container {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  padding: .5rem;
  background-color: hsl(225, 6%, 25%);
}

.editor-container.collapsed {
  flex-grow: 0;
}

.editor-container.collapsed .CodeMirror-scroll {
  position: absolute;
  overflow: hidden !important;
}

.expand-collapse-btn {
  margin-left: .5rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.editor-title {
  display: flex;
  justify-content: space-between;
  background-color: hsl(225, 6%, 13%);
  color: white;
  padding: .5rem .5rem .5rem 1rem;
  border-top-right-radius: .5rem;
  border-top-left-radius: .5rem;
}

.CodeMirror {
  height: 100% !important;
}

.code-mirror-wrapper {
  flex-grow: 1;
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
  overflow: hidden;
}