.footer-main{
    background-image: url('footer-img.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .9;
    width: 100%;
}
.footer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--style-blue-dark);
    flex-direction: column;
    padding: 20px;
    gap:50px;
    opacity: 0.9;
}
.footer-top{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
}
    @media screen and (max-width:800px) {
        .footer-top{
            flex-direction: column;
            align-items: center;
    }
}
.left-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    flex-basis: 30%;
    @media screen and (max-width:800px) {
        flex-direction: column;
        flex-basis: 100%;
        width:100%;
    }
}
.left-footer-title{
    text-align: left;
    color: var(--style-bright);
    font-weight: bolder;
}
.left-footer-content-one{
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap:30px;
}
.left-footer-content-one-wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    @media screen and (max-width:800px) {
        justify-content: left;
    }
}
.left-footer-content-one-item{
    color: var(--style-bright);
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-decoration: none;
}
.left-footer-content-one-icon{
    color: var(--style-light-gray);
}
.footer-sub-text{
    padding: 4px 10px;
    font-size: 14px;
    color: var(--gold);
    text-decoration: none;
}
.middle-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction:column;
    flex-basis: 30%;
    @media screen and (max-width:800px) {
        flex-direction: column;
        flex-basis: 100%;
        width:100%;
        margin-top:50px;
    }
}
.right-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    flex-basis: 30%;
    @media screen and (max-width:800px) {
        flex-direction: column;
        flex-basis: 100%;
        width:100%;
        margin-top:50px;
    }
}
.footer-bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.copyright{
    color: var(--style-bright);
    text-transform: capitalize;
}
