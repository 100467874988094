.learn-main{
width:100%;
height: auto;
display: flex;
margin: auto;
padding-top: 100px;
align-items: baseline;
justify-content: center;
    background: var(--style-bright);
}
    @media screen and (max-width:850px){
        .learn-main{
        flex-direction:column;
     padding: 5px;
    }
}
.learn-left{
    flex-basis: 65%;
    padding: 20px 30px;
    width: 100%;
}
    @media screen and (max-width:800px){
        .learn-left{
        flex-basis: 100%;
        padding: 20px;
    }
}
.learn-left-section-title{
    text-align: center;
    padding: 10px;
    color: var(--style-grey);
    font-size: 16px;
    font-weight: bold;
}
.learn-left-content-items{
    padding: 10px 50px;
    background:var(--style-white);
}
    @media screen and (max-width:800px){
    .learn-left-content-items{
        padding: 20px;
    }
}
.learn-title{
 font-size:20px;
 font-weight: bold;
 padding: 20px 0px;
 color: var(--style-grey);
}
.learn-body{
font-weight: 16px;
text-align: justify;
color: var(--style-grey);
}
.learn-right{
    flex-basis: 25%;
    padding: 20px 30px;
    width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
}
@media screen and (max-width:800px){
    .learn-right{
        flex-basis: 100%;
        padding: 20px;
    }
}
.newsletter-form{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background:var(--style-white);
    width: 100%;
    gap:15px;
}
.subscription-success{
color: var(--style-success);
margin-bottom: 15px;
text-align:center;
}
.subscription-error{
    color: var(--style-error);
    margin-bottom: 15px;
    text-align:center;
}
.newsletter-form-title{
text-align: center;
color: var(--style-grey);
font-size: 16px;
font-weight: 200;
margin-bottom: 15px;
} 
.newsletter-input{
height: 35px;
width: 90%;
border-radius: 5px;
padding: 5px;
border:thin solid var(--style-grey);
} 
.newsletter-btn{
    background-color: var(--style-bright);
    color: var(--style-blue-dark);
    cursor: pointer;
    padding:4px 10px;
    border-radius:4px;
    border:thin solid var(--style-grey);
}
.learn-image-loading{
    color: var(--style-blue-dark);
}