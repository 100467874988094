.home-main-container{
    width: 100%;
}
.home-main-header{
    padding-top: 70px;
}
.home-main-header-inner-wrapper{
    background-color: var(--light-bg);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.home-main-header-left{
    width: 60%;
    padding: 30px;
    height: 500px;
    background-color: var(--light-bg);
}
.home-main-header-right{
    width: 40%;
    height: 500px;
    border-bottom-left-radius: 240px;
    background-image: url(Components/header-img.PNG);
    background-repeat: no-repeat;
    background-size: cover;
    transform: scale(1);
}
.home-main-header-right:hover{
    transform: scale(1.3);
    transition: ease-in 1s;
    cursor: pointer;
}
.home-main-title{
    font-weight: bolder;
    color: rgb(65, 56, 56);
    text-align: left;
    font-size: 28px;
    padding: 50px;
}
.home-main-body{
    color: rgb(121, 114, 114);
    padding: 0px 50px;
    text-align: justify;
    font-weight: 600;
}
.home-main-action-section{
    display: flex;
    justify-content: center;
}
.home-header-btn{
    background-color: var(--danger-1) !important;
    border: 1px solid rgb(236, 21, 21) !important;
    margin-left: 50px;
    border-radius: 20px !important;
}
.home-main-cards-wrapper{
    margin-top: -50px !important;
}
.home-main-cards{

}
.home-main-card{
    background-color: var(--light-bg);
    border: none !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}
.home-card-icon{
    color: red !important;
    font-size: 50px !important;
    margin-top: 20px;
}
.home-card-title{
    font-weight: bolder;
    color: rgb(65, 56, 56);
    text-align: center;
    font-size: 24px;
    padding-top: 20px;
}
.home-card-body{
    color: rgb(65, 59, 59);
    text-align: justify;
    font-weight: 600;
    font-size: 14px;
    padding: 0px 10px;
}
.about-us-section{
    margin-top: 100px !important;
}
.about-title{
    color: var(--danger-1) !important;
    font-size: 20px;
    font-weight: 100;
    text-align: left;
    padding: 10px;
}
.about-title-bold{
color: rgb(105, 96, 96);
font-weight: bolder;
font-size: 30px;
text-align: left;
padding: 10px !important;
}
.about-body-text{
    color: rgb(105, 96, 96);
/* font-size: 20px; */
text-align: justify;
padding: 10px;
}
.sample-section{
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    flex-direction: row;
    flex-wrap: wrap;
    box-shadow: 3px 2px 3px 2px rgb(248, 247, 247);
    padding: 20px;
}
.sample-item{
    flex-basis: 45%;
    padding-top: 40px;
}
.home-content-image{
    width: 100%;
    cursor: pointer;
}
.home-content-image-1,.home-content-image-2,.home-content-image-3,.home-content-image-4{
    width: 200%;
}
.sample-item-expanded-1,.sample-item-expanded-2,.sample-item-expanded-3,
.sample-item-expanded-4{
    width: 200%;
}